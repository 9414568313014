import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row, Table } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import { ATTRIBUTE_SECTIONS } from 'lib/constants';
import { ATTRIBUTES_FOR_PRODUCT } from 'lib/networking/endpoints';
import AttributeRow from 'components/global_products/detail_view/AttributeRow';
import { GLOBAL_PRODUCT_TYPE } from 'lib/enums/ProductType';
import { GlobalProductsDetailContext } from 'components/global_products/detail_view/GlobalProductsDetailContainer';
import { grape } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SearchBar from 'components/shared/SearchBar';
import useGet from 'lib/hooks/useGet';

function GlobalProductsAttributesView({
  productId,
  productType,
  searchInput,
  setSearchInput,
  refetch,
}) {
  const [hideEmptyAttributes, setHideEmptyAttributes] = useState(true);
  const [showPropagateData, setShowPropagateData] = useState(true);
  const { setOtherImages, setThumbnailImage, setLoading } = useContext(GlobalProductsDetailContext);

  const {
    data: { data: attributes, images: imageDetails } = {},
    loading: attributesLoading,
    refetch: refetchAttributes,
  } = useGet(
    ATTRIBUTES_FOR_PRODUCT,
    {
      product_id: productId,
      product_type: productType,
      propagate_data: showPropagateData,
      get_image_data: true,
      with_owner_details: true,
      schema_id: 1,
    },
    !(productId && productType),
  );

  useEffect(() => {
    if (imageDetails) {
      setOtherImages(imageDetails.other_images);
      setThumbnailImage(imageDetails.thumbnail);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageDetails]);

  const filteredAttributes = useMemo(() => {
    if (!attributes?.length) {
      return [];
    }
    let attrResults = attributes;
    if (hideEmptyAttributes) {
      attrResults = attrResults.filter(attribute => !!attribute.attribute_value);
    }
    if (searchInput) {
      attrResults = attributes.filter(attribute => {
        const searchableText =
          attribute.attribute_name +
          attribute.attribute_section +
          attribute.attribute_sub_section +
          attribute.attribute_value;
        return searchableText.toLowerCase().includes(searchInput.toLowerCase());
      });
    }
    return attrResults.filter(
      attribute => attribute.attribute_section !== ATTRIBUTE_SECTIONS.IMAGES,
    );
  }, [attributes, hideEmptyAttributes, searchInput]);

  if (attributesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="my-4 px-4">
      <Row className="d-flex">
        <Col>
          <SearchBar
            value={searchInput}
            setValue={setSearchInput}
            placeholder="Search by attribute header, value, etc"
          />
        </Col>
        <Col className="d-flex align-items-center">
          <div className="ml-3 d-flex align-items-center">
            Hide Empty
            <ToggleSwitch
              className="ml-2"
              onColor={grape}
              checked={hideEmptyAttributes}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={e => setHideEmptyAttributes(e)}
            />
          </div>
          <div className="ml-4 d-flex align-items-center">
            Show Propagated Data
            <ToggleSwitch
              className="ml-2"
              onColor={grape}
              checked={showPropagateData}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={e => setShowPropagateData(e)}
            />
          </div>
        </Col>
      </Row>
      <Table className="mt-3">
        <thead>
          <tr />
        </thead>
        <tbody>
          {filteredAttributes.map(attribute => (
            <AttributeRow
              key={attribute.attribute_name}
              attribute={attribute}
              productId={productId}
              productType={productType}
              refetch={refetch}
              refetchAttributes={refetchAttributes}
              isGlobalProductAttribute={productType === GLOBAL_PRODUCT_TYPE}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

GlobalProductsAttributesView.propTypes = {
  productId: PropTypes.number,
  productType: PropTypes.string,
  refetch: PropTypes.func,
  searchInput: PropTypes.string,
  setSearchInput: PropTypes.func,
};

export default GlobalProductsAttributesView;
